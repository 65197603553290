import { type ClassValue, clsx } from "clsx";
import { NextResponse } from "next/server";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

/**
 * Fonction qui permet de donner l,Image de l'utilisateur sur i.pravatar.cc
 * @param userId Id de l'utilisateur
 * @param size taille de l'image
 * @returns un URL avec l'image de l'utilisateur
 */
export function GetUrlProfileImage(
	userId: string,
	imageFactor: any = "",
	size = 150
): string {
	return `https://i.pravatar.cc/${size}?u=${userId}`;
}

// /**
//  * Permet d'obtenir les initiales de l'utilisateur.
//  * @param pseudo Pseudo de l'utilisateur
//  * @returns les Initiales en majuscule de l'utilisateur
//  */
// export function GetUserInitials(pseudo: string): string {
// 	// Split the pseudo into words
// 	const words = pseudo.split(" ");

// 	// Get the first letter of the first word and the first letter of the last word
// 	const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");

// 	// Return the initials
// 	return initials;
// }

/**
 * Permet d'obtenir les initiales de l'utilisateur.
 * @param pseudo Pseudo de l'utilisateur
 * @returns les Initiales en majuscule de l'utilisateur
 */
export function GetUserInitials(pseudo?: string | null): string {
	// Return default value if pseudo is undefined or null
	if (!pseudo) return "?";

	// Trim the pseudo and check if it's empty
	const trimmedPseudo = pseudo.trim();
	if (trimmedPseudo.length === 0) return "?";

	// Split the pseudo into words
	const words = trimmedPseudo.split(" ");

	// If there are no words after splitting, return first character or default
	if (words.length === 0) return "?";

	// Get the first letter of each word
	const initials = words
		.filter((word) => word.length > 0) // Filter out empty strings
		.map((word) => word.charAt(0).toUpperCase())
		.join("");

	// Return the initials or default if no valid initials were generated
	return initials || "?";
}

/**
 * Crée un lien vers l'api pour une requête
 * @param route route de l'api
 * @returns une chaine de caractère contenant la route complète de l'api
 */
export function APIRoute(route: string): string {
	return `${process.env.NEXT_PUBLIC_API_URL}${route}`;
}
export const defaultCategorieImage = "/placeholder.svg?height=100&width=200";
export const SaltRounds = 12;

export const getRandomInt = (min: number = 0, max: number = 1000): number => {
	// Ensure min is less than max
	if (min >= max) {
		throw new Error("Min must be less than max");
	}

	// Generate random integer between min (inclusive) and max (exclusive)
	return Math.floor(Math.random() * (max - min)) + min;
};

/**
 *
 * @returns Le code de la room dans l'url si il existre
 */
export function GetRoomURLCode() {
	const hash = window.location.hash; // '#r=R'
	const params = new URLSearchParams(hash.slice(1)); // Remove the '#' and parse
	const rParam = params.get("r"); // 'R'

	console.log(rParam); // Outputs: 'R'
	return rParam;
}

/**
 * Fonction pouvant être ajusté qui retourne un url pour rejoindre le lobby
 * @param code Le code de du Lobby.
 * @param game Le jeu pour lequel on fait l'url (0 = Serpent Echelle , 1 = Sang de la Reine)
 * @returns un URL contenant le code du Lobby
 */
export function GetRoomURL(code: string, game = 0) {
	const gameURL = game == 0 ? "games/serpent-echelle#r=R" : "testgame#r=R";

	console.log(`${process.env.URL_SERPENT_ECHELLE}${gameURL}${code}`);
	return `${process.env.URL_SERPENT_ECHELLE}${gameURL}${code}`;
}

export function GetBirthday(birthday: Date | undefined): string {
	//TODO : enlever cette partie de code et afficher l'âge à la palce
	if (!birthday) {
		console.log(birthday);

		return "Invalid date"; // or handle this case as needed
	}

	const age = Math.floor(
		(Date.now() - new Date(birthday).getTime()) / (1000 * 60 * 60 * 24 * 365.25)
	);

	// Ensure it's a valid date instance
	if (!(birthday instanceof Date)) {
		return age.toString(); // or handle this case as needed
	}
	console.log(birthday);
	return age.toString();
}
