"use client";

import { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { Bell } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import { useTheme } from "@/contexts/ThemeContext";
import { IInvitation } from "@/models/invitation";
import { GetRoomURL, GetUrlProfileImage, GetUserInitials } from "@/lib/utils";
import { useRouter } from "next/navigation";
import Link from "next/link";
import { fr } from "date-fns/locale";
import { useSocket } from "@/contexts/SocketContext";
type Message = {
	id: string;
	sender: {
		name: string;
		avatar: string;
	};
	subject: string;
	content: string;
	sentAt: Date;
	read: boolean;
};

type InboxDropdownProps = {
	invitations?: IInvitation[];
	messages?: Message[];
};

const InvitationItem = ({
	invitation,
	roomUrl,
}: {
	invitation: IInvitation;
	roomUrl: string;
}) => (
	<div className="flex items-center space-x-4 p-4 border-b">
		<Avatar>
			<AvatarImage
				src={GetUrlProfileImage(
					invitation.sender.id,
					invitation.sender.imageFactor,
					350
				)}
				alt={invitation.sender.pseudo}
			/>
			<AvatarFallback>
				{GetUserInitials(invitation.sender.pseudo)}
			</AvatarFallback>
		</Avatar>
		<div className="flex-1 min-w-0">
			<p className="font-medium text-sm truncate">
				{invitation.sender.pseudo} <br /> Vous invite à jouer
				{invitation.gameId == 0 ? " Serpent Échelle" : " Sang de la reine"}
			</p>
			<p className="text-xs text-muted-foreground">
				{invitation.createdAt
					? formatDistanceToNow(new Date(invitation.createdAt?.toString()), {
							addSuffix: true,
							locale: fr,
					  })
					: ""}
			</p>
		</div>
		<Link prefetch className="btn btn-primary" href={roomUrl}>
			Joindre
		</Link>
	</div>
);

const MessageItem = ({
	message,
	onRead,
}: {
	message: Message;
	onRead: (id: string) => void;
}) => (
	<div
		className={`flex items-start space-x-4 p-4 border-b ${
			message.read ? "bg-secondary/50" : "bg-background"
		}`}
		onClick={() => !message.read && onRead(message.id)}
	>
		<Avatar>
			<AvatarImage src={message.sender.avatar} alt={message.sender.name} />
			<AvatarFallback>{message.sender.name[0]}</AvatarFallback>
		</Avatar>
		<div className="flex-1 min-w-0">
			<p className="font-medium text-sm truncate">{message.subject}</p>
			<p className="text-xs text-muted-foreground truncate">
				{message.content}
			</p>
			<p className="text-xs text-muted-foreground mt-1">
				{formatDistanceToNow(new Date(message.sentAt), { addSuffix: true })}
			</p>
		</div>
		{!message.read && (
			<div className="w-2 h-2 bg-blue-500 rounded-full flex-shrink-0" />
		)}
	</div>
);

export default function InboxDropdown({
	invitations = [],
	messages = [],
}: InboxDropdownProps) {
	const [localMessages, setLocalMessages] = useState(messages);
	const [localInvitations, setLocalInvitations] = useState(invitations);
	const [isOpen, setIsOpen] = useState(false);
	const socket = useSocket();
	const handleReadMessage = (id: string) => {
		setLocalMessages((prevMessages) =>
			prevMessages.map((msg) => (msg.id === id ? { ...msg, read: true } : msg))
		);
	};

	const router = useRouter();


	const handleJoinGame = (id: string) => {
		console.log(`Joining game with invitation ID: ${id}`);
		setLocalInvitations((prevInvitations) =>
			prevInvitations.filter((inv) => inv.id !== id)
		);
		router.push(`R${id}`);
	};

	const unreadCount =
		messages.filter((msg) => !msg.read).length + invitations.length;
	const { theme } = useTheme();

	return (
		<Popover open={isOpen} onOpenChange={setIsOpen}>
			<PopoverTrigger asChild>
				<button className="relative text-white hover:bg-white/50 active:bg-white/70 transition-all rounded-full p-2">
					<Bell />
					{unreadCount > 0 && (
						<span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
							{unreadCount}
						</span>
					)}
					<span className="sr-only">Open inbox</span>
				</button>
			</PopoverTrigger>
			<PopoverContent
				align="end"
				className={`w-80 ${
					theme === "dark" ? "bg-slate-700" : "bg-slate-300"
				} sm:w-96 p-0`}
			>
				<Tabs defaultValue="messages" className="w-full">
					<TabsList className="grid w-full grid-cols-2">
						<TabsTrigger value="messages">
							Messages ({messages.length})
						</TabsTrigger>
						<TabsTrigger value="invitations">
							Invitations ({invitations.length})
						</TabsTrigger>
					</TabsList>
					<TabsContent value="messages">
						<ScrollArea className="h-[300px] sm:h-[400px]">
							{messages.length > 0 ? (
								messages.map((message) => (
									<MessageItem
										key={message.id}
										message={message}
										onRead={handleReadMessage}
									/>
								))
							) : (
								<p className="text-center py-4 text-muted-foreground">
									Aucun message
								</p>
							)}
						</ScrollArea>
					</TabsContent>
					<TabsContent value="invitations">
						<ScrollArea className="h-[300px] sm:h-[400px]">
							{invitations.length > 0 ? (
								invitations.map((invitation) => (
									<InvitationItem
										key={invitation.id}
										invitation={invitation}
										roomUrl={`${GetRoomURL(invitation.roomCode)}`}
									/>
								))
							) : (
								<p className="text-center py-4 text-muted-foreground">
									Aucune invitation
								</p>
							)}
						</ScrollArea>
					</TabsContent>
				</Tabs>
			</PopoverContent>
		</Popover>
	);
}
