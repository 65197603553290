"use client";
import Link from "next/link";

export default function MainLogo() {
	return (
		<Link href="/" className="flex items-center" prefetch={false}>
			<MountainIcon className="h-6 w-[5vw] sm:w-[5vw] md:w-[4vw]" />
			<div>
				<span className="text-2xl md:text-4xl font-semibold text-primary">
					Game
				</span>
				<span className="text-2xl md:text-4xl font-semibold text-accent">
					Sphère
				</span>
			</div>
		</Link>
	);
}

//TODO : Changer le logo
function MountainIcon(props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="m8 3 4 8 5-5 5 15H2L8 3z" />
		</svg>
	);
}
