import { GetUrlProfileImage } from "@/lib/utils";
import { ISimpleUser } from "@/models/user";
import Image from "next/image";

interface InvitationToastProps {
	sender: ISimpleUser;
	onAccept: () => void;
	onDecline: () => void;
}

const InvitationToast = ({
	sender,
	onAccept,
	onDecline,
}: InvitationToastProps) => (
	<div className="flex items-center p-4 bg-gray-800 rounded-lg shadow-lg border border-gray-700 max-w-sm">
		{/* Avatar */}
		<Image
			src={GetUrlProfileImage(sender.id, sender.imageFactor)}
			alt={`${sender.pseudo}'s avatar`}
			width={50}
			height={50}
			className="rounded-full mr-4"
		/>

		{/* Message */}
		<div className="flex-1 text-white">
			<p className="font-semibold text-lg">{sender.pseudo}</p>
			<p className="text-gray-400">Invitattion à Jouer!</p>
		</div>

		{/* Action Buttons */}
		<div className="flex space-x-2 ml-3">
			<button
				onClick={onAccept}
				className="px-3 py-1 bg-primary/50 text-primary text-sm font-medium rounded-full hover:bg-primary/70 active:bg-primary active:text-background transition-all"
			>
				ReJoindre
			</button>
			<button
				onClick={onDecline}
				className="px-3 py-1 bg-accent/50 text-accent text-sm font-medium rounded-full hover:bg-accent/70 active:bg-accent active:text-background transition-all"
			>
				Refuser
			</button>
		</div>
	</div>
);

export default InvitationToast;
